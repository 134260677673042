import React, { forwardRef, useContext } from "react";
import styled from "@emotion/styled";

import Profile from "../../assets/icons/Profile.svg";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../context/Context";
import storage from "../../services/storageService";

import DividerH from "../DividerH";
import { useIntl } from "react-intl";

const ProfileMenu = forwardRef(
  ({ isProfile, setIsProfile, userName }, ref2) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { user, setUser } = useContext(AppContext);

    const handleLogout = () => {
      history.push("/");

      setUser(null);
      storage?.remove("refreshToken");
      storage?.remove("token");
      storage?.remove("user");
      storage?.remove("profile");
    };

    return (
      <div ref={ref2} style={{ position: "relative" }}>
        <Container
          onClick={() => setIsProfile(!isProfile)}
          isProfile={isProfile}
        >
          <p style={{ color: "#fff" }}>{userName}</p>
          <Img src={Profile} />
        </Container>
        <Tooltip isOpen={isProfile}>
          <Link to={"/settings/personal-info"}>
            <Option
              onClick={() => {
                setIsProfile(false);
              }}
            >
              {formatMessage({ id: "profile" })}
            </Option>
          </Link>
          <DividerH />
          <Option
            onClick={() => {
              setIsProfile(false);
              handleLogout();
            }}
          >
            {formatMessage({ id: "sign_out" })}
          </Option>
        </Tooltip>
      </div>
    );
  }
);

export default ProfileMenu;

const Container = styled.div`
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Tooltip = styled.div`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1),
    inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 6px;
  padding: 8px;

  position: absolute;
  top: 40px;
  right: 0px;
  /* z-index: 1000; */
  cursor: pointer;
  z-index: 1;
  display: grid;
  gap: 4px;
  /* grid-template-columns: 150px; */
  /* grid-template-columns: 70px; */
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: 0.2s ease-in-out;
  /* display: ${(props) => (props.isOpen ? "block" : "none")}; */
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isOpen
      ? "skewY(0deg) rotate(0deg) translateX(0px) translateY(0px)"
      : "skewY(0deg) rotate(0deg) translateX(0px) translateY(-10px)"};

  @media screen and (max-width: 375px) {
    /* right: 20px; */
  }
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  :hover {
    background-color: rgba(145, 158, 171, 0.08);
  }
  img {
    border-radius: 8px;
  }
`;

const Img = styled.img`
  border-radius: 50%;

  background-color: #f3f4f5;
  padding: 4px;
`;
