import React, { forwardRef } from "react";
import styled from "@emotion/styled";

import kazakhstan from "../../assets/img/kazakhstan.png";
import russia from "../../assets/img/russia.png";
import uk from "../../assets/img/united-kingdom.png";
import { ReactComponent as ChevronDown } from "../../assets/img/landing/chevron_down.svg";
import { ReactComponent as Globe } from "../../assets/icons/Globe.svg";
import storage from "../../services/storageService";
const SelectLanguage = forwardRef(
  ({ isLanguage, setIsLanguage, lang, setLang }, ref) => {
    return (
      <div ref={ref} style={{ position: "relative" }}>
        <Lang
          onClick={() => setIsLanguage(!isLanguage)}
          isLanguage={isLanguage}
        >
          <GlobeContainer ><Globe fill="#fff" /></GlobeContainer>
          <div style={{ color: "#fff" }}>{lang}</div>

          <ChevronDown
            fill="#fff"
            style={{
              transform: isLanguage ? "rotate(-180deg)" : "rotate(0deg)",
              transition: "all 0.2s ease-in-out",
            }}
          />
        </Lang>
        <Tooltip isOpen={isLanguage}>
          <Option
            onClick={() => {
              setLang("kz");
              setIsLanguage(false);
              storage?.set('momentLang', 'kk')
            }}
          >
            <img src={kazakhstan} /> Қазақша
          </Option>
          <Option
            onClick={() => {
              setLang("ru");
              setIsLanguage(false);
              storage?.set('momentLang', 'ru')
            }}
          >
            <img src={russia} />
            Русский
          </Option>
          {/* <Option
            onClick={() => {
              setLang("en");
              setIsLanguage(false);
              storage?.set('momentLang', 'en')
            }}
          >
            <img src={uk} />
            English
          </Option> */}
        </Tooltip>
      </div>
    );
  }
);

export default SelectLanguage;

const Lang = styled.div`
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  img {
    transform: ${(props) =>
      props.isLanguage ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: 0.1s ease-in-out;
  }
`;

const Tooltip = styled.div`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1),
    inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 6px;
  padding: 8px;

  position: absolute;
  top: 32px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
  display: grid;
  gap: 4px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: 0.2s ease-in-out;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isOpen
      ? "skewY(0deg) rotate(0deg) translateX(0px) translateY(0px)"
      : "skewY(0deg) rotate(0deg) translateX(0px) translateY(-10px)"};
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  :hover {
    background-color: rgba(145, 158, 171, 0.08);
  }
  img {
    border-radius: 8px;
  }
`;

const GlobeContainer = styled.div`
  @media screen and (max-width: 500px) {
    display: none;
  }
`