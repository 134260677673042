import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Router } from "react-router";
import { CircularProgress, Box } from "@material-ui/core";

import PrivateRouter from "./PrivateRouter";
import CustomPage from "../pages/CustomPage";
import { UseScript } from "../utils/UseScript";
import SignInRedirect from "../pages/LandingPage/SignIn/SignInRedirect";

// Pages
const LandingPage = React.lazy(() => import("../pages/LandingPage"));

const SignUp = React.lazy(() => import("../pages/LandingPage/SignUp/SignUp"));
const SignIn = React.lazy(() => import("../pages/LandingPage/SignIn/SignIn"));
const ConfirmMail = React.lazy(() =>
  import("../pages/LandingPage/SignUp/ConfirmMail")
);
const UpdatePasswordByEmail = React.lazy(() =>
  import("../pages/LandingPage/ForgotPassword/UpdatePasswordByEmail")
);
const PasswordChanged = React.lazy(() =>
  import("../pages/LandingPage/ForgotPassword/PasswordChanged")
);
const ForgotPassword = React.lazy(() =>
  import("../pages/LandingPage/ForgotPassword/ForgotPassword")
);
const CheckMail = React.lazy(() =>
  import("../pages/LandingPage/ForgotPassword/CheckMail")
);
const ResetPassword = React.lazy(() =>
  import("../pages/LandingPage/ForgotPassword/ResetPassword")
);
const Dashboard = React.lazy(() => import("../pages/Dashboards"));
const ModeratorsPage = React.lazy(() => import("../pages/ModeratorsPage"));
const EditorsPage = React.lazy(() => import("../pages/EditorsPage"));
const CustomersPage = React.lazy(() => import("../pages/CustomersPage"));
const SchoolAdminPage = React.lazy(() => import("../pages/SchoolAdminPage"));
const ExamPreviewPage = React.lazy(() =>
  import("../pages/ExamSetupPage/ExamPreviewPage")
);
const StudentPage = React.lazy(() => import("../pages/StudentPage"));
const UniversityPage = React.lazy(() => import("../pages/UniversityPage"));
const UniversitySpecialityPage = React.lazy(() =>
  import("../pages/UniversitySpecialityPage")
);

const SpecialityGroupPage = React.lazy(() =>
  import("../pages/SpecialityGroupPage")
);
const SpecialityPage = React.lazy(() => import("../pages/SpecialityPage"));
const ExpertsPage = React.lazy(() => import("../pages/ExpertsPage"));
const SalesmanPage = React.lazy(() => import("../pages/SalesmanPage"));
const TopicsPage = React.lazy(() => import("../pages/TopicsPage"));
const SubjectsPage = React.lazy(() => import("../pages/SubjectsPage"));
const QuestionsPage = React.lazy(() => import("../pages/QuestionsPage"));
const QuestionsCount = React.lazy(() =>
  import("../pages/QuestionsPage/QuestionsCount")
);
const QuestionCreate = React.lazy(() =>
  import("../pages/QuestionsPage/QuestionCreate")
);
const QuestionEdit = React.lazy(() =>
  import("../pages/QuestionsPage/QuestionEdit")
);
const QuestionPreview = React.lazy(() =>
  import("../pages/QuestionsPage/QuestionPreviewPage")
);
const QuestionDuplicate = React.lazy(() =>
  import("../pages/QuestionsPage/QuestionDuplicate")
);
const ParagraphSetupPage = React.lazy(() =>
  import("../pages/ParagraphSetupPage")
);
const ParagraphEdit = React.lazy(() =>
  import("../pages/ParagraphSetupPage/ParagraphEdit")
);
const ExamSetupPage = React.lazy(() => import("../pages/ExamSetupPage"));
const ExamStudents = React.lazy(() =>
  import("../pages/ExamSetupPage/ExamStudents")
);
const ExamSetupEdit = React.lazy(() =>
  import("../pages/ExamSetupPage/ExamSetupEdit")
);
const ExamTypePage = React.lazy(() => import("../pages/ExamTypePage"));
const ExamTypeEdit = React.lazy(() =>
  import("../pages/ExamTypePage/ExamTypeEdit")
);
const AccountPage = React.lazy(() => import("../pages/AccountPage"));
const TariffsAndDiscountsPage = React.lazy(() =>
  import("../pages/TariffsAndDiscountsPage")
);
const TariffsPackagePage = React.lazy(() =>
  import("../pages/TariffsAndDiscountsPage/TariffsPackagePage")
);

const CompletedExams = React.lazy(() =>
  import("../pages/StudentPage/CompletedExams")
);
const PassExam = React.lazy(() => import("../pages/StudentPage/PassExam"));
const UserRegisteredExam = React.lazy(() =>
  import("../pages/StudentPage/PassExam/UserRegisteredExam")
);
const PendingExams = React.lazy(() =>
  import("../pages/StudentPage/PendingExams")
);
const ProcessingExams = React.lazy(() =>
  import("../pages/StudentPage/ProcessingExams")
);
const SelectExamSubjects = React.lazy(() =>
  import("../pages/StudentPage/PendingExams/SelectExamSubjects")
);
const SelectSubExamAndLanguage = React.lazy(() =>
  import("../pages/StudentPage/PassExam/SelectSubExamAndLanguage")
);
const ExamResultPreviewPage = React.lazy(() =>
  import("../pages/StudentPage/ExamResult/ExamResultPreviewPage")
);

const SubjectExamPage = React.lazy(() =>
  import("../pages/StudentPage/PendingExams/SubjectExamPage")
);
const ExamResult = React.lazy(() => import("../pages/StudentPage/ExamResult"));
const PurchaseExams = React.lazy(() =>
  import("../pages/StudentPage/PurchaseExams/PurchaseExams")
);
const PurchaseExamSuccess = React.lazy(() =>
  import("../pages/StudentPage/PurchaseExamsOld/PurchaseExamSuccess")
);
const PurchaseExamFailure = React.lazy(() =>
  import("../pages/StudentPage/PurchaseExamsOld/PurchaseExamFailure")
);
const StudentSetupPage = React.lazy(() => import("../pages/StudentSetupPage"));
const StudentsListPage = React.lazy(() =>
  import("../pages/StudentSetupPage/StudentsList")
);
const CallbackPage = React.lazy(() => import("../pages/CallbackPage"));
const SettingsPage = React.lazy(() => import("../pages/SettingsPage"));
const Location = React.lazy(() => import("../pages/LocationPage"));
const District = React.lazy(() => import("../pages/LocationPage/District"));
const School = React.lazy(() => import("../pages/LocationPage/School"));
const ExamAnalysisPage = React.lazy(() => import("../pages/ExamAnalysisPage"));
const ExamAnalysis = React.lazy(() =>
  import("../pages/ExamAnalysisPage/ExamAnalysis")
);
const history = require("history").createBrowserHistory();

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Suspense
          fallback={
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/ru" component={LandingPage}  />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/sign-in-redirect" component={SignInRedirect} />
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/check-email" component={CheckMail} />
          <Route exact path="/password-changed" component={PasswordChanged} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route
            exact
            path="/api/auth/mail-activate/:token"
            component={ConfirmMail}
          />
          <Route
            exact
            path="/api/auth/check-code/:token"
            component={UpdatePasswordByEmail}
          />
          <Route exact path="/custom-page" component={CustomPage} />
          <Route component={DefaultContainer} />
        </Suspense>
      </Switch>
    </Router>
  );
};

const DefaultContainer = () => (
  <div>
    <PrivateRouter exact path="/dashboard" component={Dashboard} />
    <PrivateRouter exact path="/moderators" component={ModeratorsPage} />
    <PrivateRouter exact path="/editors" component={EditorsPage} />
    <PrivateRouter exact path="/customers" component={CustomersPage} />
    <PrivateRouter exact path="/school-admin" component={SchoolAdminPage} />
    <PrivateRouter exact path="/student" component={StudentPage} />
    <PrivateRouter exact path="/salesman" component={SalesmanPage} />
    <PrivateRouter exact path="/experts" component={ExpertsPage} />
    <PrivateRouter
      exact
      path="/students/pending"
      component={StudentSetupPage}
    />
    <PrivateRouter exact path="/studentsList" component={StudentsListPage} />
    <PrivateRouter exact path="/students" component={StudentSetupPage} />
    <PrivateRouter exact path="/topics" component={TopicsPage} />
    <PrivateRouter exact path="/subjects" component={SubjectsPage} />
    <PrivateRouter exact path="/questions/new" component={QuestionCreate} />
    <PrivateRouter exact path="/questions/edit/:id" component={QuestionEdit} />
    <PrivateRouter
      exact
      path="/questions/duplicate/:id"
      component={QuestionDuplicate}
    />
    <PrivateRouter
      exact
      path="/question/preview/:id"
      component={QuestionPreview}
    />
    <PrivateRouter exact path="/questions/pending" component={QuestionsPage} />
    <PrivateRouter exact path="/questions-count" component={QuestionsCount} />
    <PrivateRouter exact path="/questions" component={QuestionsPage} />
    <PrivateRouter exact path="/exam-setup" component={ExamSetupPage} />
    <PrivateRouter exact path="/exam-students/:id" component={ExamStudents} />
    <PrivateRouter exact path="/exam-setup/new" component={ExamSetupEdit} />
    <PrivateRouter exact path="/location" component={Location} />
    <PrivateRouter exact path="/district/:id" component={District} />
    <PrivateRouter exact path="/school/:districtId/:id" component={School} />
    <PrivateRouter
      exact
      path="/exam-setup/edit/:id"
      component={ExamSetupEdit}
    />
    <PrivateRouter exact path="/paragraphs/new" component={ParagraphEdit} />
    <PrivateRouter
      exact
      path="/paragraphs/pending"
      component={ParagraphSetupPage}
    />
    <PrivateRouter exact path="/paragraphs" component={ParagraphSetupPage} />
    <PrivateRouter
      exact
      path="/paragraphs/edit/:id"
      component={ParagraphEdit}
    />
    <PrivateRouter exact path="/exam-type" component={ExamTypePage} />
    <PrivateRouter
      exact
      path="/exam-type/edit/:examTypeId"
      component={ExamTypeEdit}
    />
    <PrivateRouter exact path="/account" component={AccountPage} />
    <PrivateRouter exact path="/callback" component={CallbackPage} />
    <PrivateRouter
      exact
      path="/tariffs-discounts"
      component={TariffsAndDiscountsPage}
    />
    <PrivateRouter
      exact
      path="/tariffs-package/:tariffId"
      component={TariffsPackagePage}
    />
    <PrivateRouter exact path="/pass-exam" component={PassExam} />
    <PrivateRouter
      exact
      path="/user-pass-exam/:id"
      component={UserRegisteredExam}
    />
    <PrivateRouter exact path="/completed-exams" component={CompletedExams} />
    <PrivateRouter exact path="/pending-exams" component={PendingExams} />
    <PrivateRouter exact path="/processing-exams" component={ProcessingExams} />
    <PrivateRouter
      exact
      path="/select-exams-subjects"
      component={SelectExamSubjects}
    />
    <PrivateRouter
      exact
      path="/select-exams-subjects"
      component={SelectSubExamAndLanguage}
    />
    <PrivateRouter
      exact
      path="/subject-exam-page/:studentResultId"
      component={SubjectExamPage}
      fullScreen
    />
    <PrivateRouter
      exact
      path="/exam-result-preview/:examId/:examRoundId"
      component={ExamResultPreviewPage}
      fullScreen
    />
    <PrivateRouter
      exact
      path="/exam-preview"
      component={ExamPreviewPage}
      fullScreen
    />
    <PrivateRouter
      exact
      path="/exam-result/:studentResultId"
      component={ExamResult}
    />
    <PrivateRouter exact path="/purchase-exams" component={PurchaseExams} />
    <PrivateRouter
      exact
      path="/purchase-exam-success"
      component={PurchaseExamSuccess}
    />
    <PrivateRouter
      exact
      path="/purchase-exam-failure"
      component={PurchaseExamFailure}
    />
    <PrivateRouter exact path="/settings" component={SettingsPage} />
    <PrivateRouter exact path="/university" component={UniversityPage} />
    <PrivateRouter
      exact
      path="/university-speciality/:universityId"
      component={UniversitySpecialityPage}
    />
    <PrivateRouter
      exact
      path="/speciality-group"
      component={SpecialityGroupPage}
    />
    <PrivateRouter
      exact
      path="/speciality/:specialityGroupId"
      component={SpecialityPage}
    />
    <PrivateRouter exact path="/settings/:menu" component={SettingsPage} />
    <PrivateRouter exact path="/exam-analysis" component={ExamAnalysisPage} />
    <PrivateRouter
      exact
      path="/exam-analysis/:examId"
      component={ExamAnalysis}
    />
  </div>
);

export default AppRouter;
/* const menuLinks = [
  {
    id: 1,
    title: <FormattedMessage id="moderators" />,
    icon: AssignmentTurnedIn,
    link: "/moderators",
  },
  {
    id: 2,
    title: <FormattedMessage id="editors" />,
    icon: Assignment,
    link: "/editors",
  },
  {
    id: 3,
    title: <FormattedMessage id="topicSetup" />,
    icon: Assignment,
    link: "/topics",
  },
  {
    id: 4,
    title: <FormattedMessage id="questions" />,
    icon: Help,
    link: "/questions",
  },
  {
    id: 5,
    title: <FormattedMessage id="teachers" />,
    icon: LocalLibrary,
    link: "/teachers",
  },
  {
    id: 6,
    title: <FormattedMessage id="salesman" />,
    icon: MonetizationOn,
    link: "/salesman",
  },
  {
    id: 7,
    title: <FormattedMessage id="schoolAdmin" />,
    icon: SupervisorAccount,
    link: "/school-admin",
  },
  {
    id: 8,
    title: <FormattedMessage id="examSetup" />,
    icon: AssignmentTurnedIn,
    link: "/exam-type",
  },
]; */
