import React, { forwardRef, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";

import Profile from "../../assets/icons/Profile.svg";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../context/Context";
import storage from "../../services/storageService";

import DividerH from "../DividerH";
import { fetchData } from "../../services/fetchData";
import { useIntl } from "react-intl";
import { REACT_APP_IAM_SERVICE_BASE_URL } from "../../utils/constants";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import "moment/locale/kk";
import "moment/locale/ru";
const CurrentSubscription = () => {
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const [subscription, setSubscription] = useState("");
  const [resultInfo, setResultInfo] = useState("");
  const roles = storage?.get("user").roles;

  useEffect(() => {
    if (roles.includes("STUDENT")) {
      getSubscritions();
    }
  }, []);

  useEffect(() => {
    if (roles.includes("STUDENT")) {
      getSubscritionInfo(resultInfo);
    }
  }, [locale]);

  const getSubscritions = async () => {
    const result = await fetchData(
      "get",
      `${REACT_APP_IAM_SERVICE_BASE_URL}/subscription/current`
    );

    if ((result.status == 200 || result.status == 204) && result?.data?.data) {
      getSubscritionInfo(result?.data?.data);
      setResultInfo(result?.data?.data);
    }
  };

  const getSubscritionInfo = (subscriptionInfo) => {
    let startDate = new Date();
    let endDate = new Date(subscriptionInfo.end_date_time);
    let totalDays = "";
    if (isToday(endDate)) {
      setSubscription(
        endDate.getHours() -
          startDate.getHours() +
          1 +
          " " +
          formatMessage({ id: "hours" })
      );
    } else {
      var given = moment([
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
      ]).locale(storage?.get("momentLang") || "ru");
      var current = moment([
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
      ]).locale(storage?.get("momentLang") || "ru");
      //   let difference =
      //   moment(subscriptionInfo.end_date_time) - moment().startOf('day');
      //totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      setSubscription(given.from(current, true));
    }
  };

  function isToday(date) {
    const today = new Date();

    console.log(today);

    if (today.toDateString() === date.toDateString()) {
      return true;
    }
    return false;
  }

  return (
    <div style={{ position: "relative" }}>
      {subscription != "Invalid date" && roles.includes("STUDENT") ? (
        <Chip
          style={{ border: "1px solid #FFFFFF", color: "#FFFFFF" }}
          variant="outlined"
          size="small"
          //icon={<FaceIcon />}
          label={formatMessage(
            { id: "leftSubscription" },
            { value: subscription }
          )}
          clickable
          color="primary"
          onClick={() => history.push("/settings/subscription")}
        />
      ) : null}
    </div>
  );
};

export default CurrentSubscription;
